import Head from 'next/head';

import Image from 'next/image';

import {
  Box,
  Button,
  Container
} from '@mui/material';

import TopNav from '../components/TopNav';
import BottomNav from '../components/BottomNav';



export default function Custom404() {
  
  return (
    <>
      <Head>
        <title>404 This page could not be found | MechanicBuddy</title>
      </Head>
      <TopNav />

      <Container
        maxWidth="sm"
        sx={{
          alignItems: "center",
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Box
          fontSize="h4.fontSize"
          textAlign="center"
          mb={2}
        >
          Oops! Page not found
        </Box>
        <Image
          src="/icons/404.svg"
          width={200}
          height={200}
          alt="End of road"
        />
        <Box
          fontSize="h5.fontSize"
          textAlign="center"
          my={2}
        >
          The page you are looking for doesn’t exist or another error occurred. Make a u-turn to safety.
        </Box>

        <Button
          color="secondary"
          variant="contained"
          href="/"
        >Go Home</Button>
      </Container>

      <BottomNav />
    </>
  )
}
